import i18next from 'i18next';
import { ErrorCode } from '../enum/error';
import { Country } from '../enum/user';

/* We specify this rule because we don't know the payload type, and we use explicitly error as object after.*/
/* So this is an emergency case, don't reproduce */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const getMessageError = (error: any): string => {
  let message = i18next.t('error.generic', { ns: 'common' });
  if ('data' in error) {
    if (typeof error.data === 'object') {
      // Handle error from our backend
      if (Object.values(ErrorCode).includes(error.data.error)) {
        message = i18next.t(error.data.error, { ns: 'error' });
      } else if (error.status !== 500) {
        message = error.data.message;
      }
    }
  }

  return message;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.toLowerCase().charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

export const countries = [
  Object.values(Country).map((country) => {
    return {
      label: i18next.t(`countries.${country.toLowerCase()}`, { ns: 'common' }),
      value: country
    };
  })
];

export const viteMode = (): string => {
  let mode = '';
  if (import.meta.env.MODE === 'development' && import.meta.env.VITE_GOOGLE_CLOUD_DEV) {
    mode = 'dev';
  } else if (import.meta.env.MODE === 'development') {
    mode = 'local';
  }
  return mode;
};
