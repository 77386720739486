import { useTranslation } from 'react-i18next';
import styles from './onboarding.module.scss';
import { Text } from '@anatoscope/circlestorybook';
import { ColorPropsEnum } from '../../../../enum/color';

const Onboarding = () => {
  const { t } = useTranslation(['auth']);

  return (
    <article className={styles['onboarding']}>
      <div className={styles['onboarding__content']}>
        <Text
          label={t('onboarding.hello')}
          type="title"
          color={ColorPropsEnum.WHITE}
          size="s"
          bold={true}
        />
        <div>
          <Text label={t(`onboarding.login.text-1`)} color={ColorPropsEnum.WHITE} />

          <Text label={t('onboarding.login.text-2')} color={ColorPropsEnum.WHITE} />
        </div>
        <img
          src="/image/register-ipad.svg"
          alt="ipad"
          className={styles['onboarding__content__image']}
        />
        <div>
          <Text
            label={t(`onboarding.login.text-3`)}
            bold={true}
            textAlign="center-align"
            color={ColorPropsEnum.WHITE}
            size="s"
          />
        </div>
      </div>
    </article>
  );
};

export default Onboarding;
