import React, { useState } from 'react';
import styles from './login-page.module.scss';
import { AttentionBox, Box, Button, Link, Text, TextField } from '@anatoscope/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { authActions } from '../../../store/auth/auth.reducers';
import { useTranslation } from 'react-i18next';
import {
  attentionBoxErrorSelector,
  fieldErrorSelector
} from '../../../store/feedback/feedback.selector';
import { feedbackActions } from '../../../store/feedback/feedback.reducer';
import { ColorPropsEnum } from '../../../enum/color';
import ReactDOM from 'react-dom';
import Onboarding from './onboarding/Onboarding';

const LoginPage = () => {
  const { t } = useTranslation(['auth']);
  const dispatch = useAppDispatch();

  const [emailFieldOptions, setEmailFieldOptions] = useState({
    variant: ColorPropsEnum.DEFAULT,
    helperText: ''
  });
  const [passwordFieldOptions, setPasswordFieldOptions] = useState({
    variant: ColorPropsEnum.DEFAULT,
    helperText: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isOpenLoginModal, setisOpenLoginModal] = useState(false);

  // Selectors
  const attentionBoxError = useAppSelector(attentionBoxErrorSelector);
  const fieldError = useAppSelector(fieldErrorSelector);

  const handleEmailOnBlur = () => {
    if (emailFieldOptions.variant != ColorPropsEnum.DEFAULT)
      setEmailFieldOptions({
        variant: ColorPropsEnum.DEFAULT,
        helperText: ''
      });
  };

  const handlePasswordOnBlur = () => {
    if (passwordFieldOptions.variant != ColorPropsEnum.DEFAULT)
      setPasswordFieldOptions({
        variant: ColorPropsEnum.DEFAULT,
        helperText: ''
      });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email.length > 0 && password.length > 0) {
      try {
        setIsLoading(true);
        await dispatch(authActions.login({ username: email, password })).unwrap();
      } catch (error) {
        return;
      } finally {
        setIsLoading(false);
      }
    } else {
      if (email.length === 0) {
        setEmailFieldOptions({
          variant: ColorPropsEnum.DANGER,
          helperText: t('error.emptyEmail')
        });
      }
      if (password.length === 0) {
        setPasswordFieldOptions({
          variant: ColorPropsEnum.DANGER,
          helperText: t('error.emptyPassword')
        });
      }
    }
  };

  if (fieldError) {
    setEmailFieldOptions({
      variant: ColorPropsEnum.DANGER,
      helperText: fieldError.message
    });
  }

  const handleCloseErrorMessage = () => {
    dispatch(feedbackActions.resetAttentionBoxError());
  };

  return (
    <div className={styles['login-page']}>
      <header className={styles['login-page__header']}>
        <img
          src="/logo/logo-circle-title-white.svg"
          alt="Circle logo"
          className={styles['login-page__header__logo']}
        />
      </header>

      <div className={styles['login-page__text']}>
        <img
          src="/logo/logo-one.svg"
          alt="Circle logo"
          className={styles['login-page__logo-one']}
        />
        <p className={styles['login-page__text--purple']}>{t('landingPage.title')}</p>
        <p className={styles['login-page__text--white']}>{t('landingPage.subtitle')}</p>
      </div>

      {isOpenLoginModal &&
        ReactDOM.createPortal(
          <>
            <div
              onClick={() => setisOpenLoginModal(false)}
              className={[
                styles['login-page__modale__background'],
                [
                  isOpenLoginModal
                    ? styles['login-page__modale__background--fade-in-modal']
                    : styles['login-page__modale__background--fade-out-modal']
                ]
              ].join(' ')}>
              {' '}
            </div>
            <Box
              padding="spacing-0"
              className={[
                styles['login-page__modale__content'],
                [
                  isOpenLoginModal
                    ? styles['login-page__modale__content--opened']
                    : styles['login-page__modale__content--closed']
                ]
              ].join(' ')}
              radius="large"
              color={ColorPropsEnum.WHITE}>
              <div className={styles['login-page__modale__wrapper']}>
                <Text
                  label={t('title')}
                  type="title"
                  size="m"
                  textAlign="center-align"
                  color={ColorPropsEnum.PRIMARY}
                  className={styles['login-page__modale__content__title']}
                  bold={true}
                />
                {attentionBoxError && (
                  <AttentionBox
                    withoutIcon={false}
                    mode="danger"
                    className={styles['login-page__message']}
                    onClose={handleCloseErrorMessage}
                    text={attentionBoxError.message}
                  />
                )}
                <form
                  noValidate
                  id="login-page__section__form"
                  className={styles['login-page__modale__content__form']}
                  onSubmit={handleSubmit}>
                  <TextField
                    label={t('email')}
                    id="email"
                    value={email}
                    placeholder="email@circle.com"
                    type="email"
                    variant={emailFieldOptions.variant}
                    helperText={emailFieldOptions.helperText}
                    wrapperClassName={styles['login-page__modale__content__form__input']}
                    onChange={(evt: Event) => setEmail((evt.target as HTMLInputElement).value)}
                    onBlur={handleEmailOnBlur}
                    radius="full"
                  />
                  <TextField
                    label={t('password')}
                    id="password"
                    value={password}
                    placeholder={t('password')}
                    type="password"
                    variant={passwordFieldOptions.variant}
                    helperText={passwordFieldOptions.helperText}
                    wrapperClassName={styles['login-page__modale__content__form__input']}
                    onChange={(evt: Event) => setPassword((evt.target as HTMLInputElement).value)}
                    onBlur={handlePasswordOnBlur}
                    radius="full"
                  />
                  <Link
                    data-cy="forgotten-password"
                    className={styles['login-page__modale__content__form__forgotten-password']}
                    color={ColorPropsEnum.PRIMARY}
                    href="#"
                    label={t('forgotPassword')}
                    size="s"
                  />

                  <div className={styles['login-page__modale__content__form__button']}>
                    <Button
                      label={t('buttonSubmit')}
                      isLoading={isLoading}
                      type="submit"
                      variant={ColorPropsEnum.SUCCESS}
                      className={styles['login-page__modale__content__form__button__submit']}
                    />
                  </div>
                </form>
              </div>
              <Onboarding />
            </Box>
          </>,
          document.getElementById('portal-root')!
        )}

      <div className={styles['login-page__button--wrapper']}>
        <Button
          label={t('landingPage.button')}
          onClick={() => setisOpenLoginModal(true)}
          variant={ColorPropsEnum.SUCCESS}
          className={styles['login-page__button']}
        />
      </div>
    </div>
  );
};

export default LoginPage;
